import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch, withRouter} from "react-router-dom";
import {isMobile} from "react-device-detect";
import {
    useBootstrap,
    useCachedByDateRange,
    useEntities,
    useFilters,
    useProgress,
    useRenderer
} from "@atttomyx/shared-hooks";
import {usePushEvent} from "@atttomyx/react-hooks";
import {
    AboutPage,
    AccountSettingsPage,
    AddUserPage,
    ChangePasswordPage,
    EditUserPage,
    ForgotPasswordPage,
    JoinAccountPage,
    LoginPage,
    MyAccountPage,
    NewUserPage,
    NotFoundPage,
    NotificationsPage,
    PhoneStatusBar,
    ProfilePage,
    PublicHeader,
    RedirectWithParams,
    Splash,
    UserSettingsPage,
    WelcomePage
} from "@atttomyx/react-components";
import Footer from "./components/footer/footer";
import IntroPage from "./pages/introPage/introPage";
import AttendancePage from "./pages/attendancePage/attendancePage";
import AttendanceReportPage from "./pages/attendanceReportPage/attendanceReportPage";
import MyAttendancePage from "./pages/myAttendancePage/myAttendancePage";
import VolunteerReportPage from "./pages/volunteerReportPage/volunteerReportPage";
import TradeReportPage from "./pages/tradeReportPage/tradeReportPage";
import SchedulePage from "./pages/schedulePage/schedulePage";
import AddShiftPage from "./pages/addShiftPage/addShiftPage";
import EditShiftPage from "./pages/editShiftPage/editShiftPage";
import TimeOffPage from "./pages/timeOffPage/timeOffPage";
import AddTimeOffPage from "./pages/addTimeOffPage/addTimeOffPage";
import EditTimeOffPage from "./pages/editTimeOffPage/editTimeOffPage";
import AttributesPage from "./pages/attributesPage/attributesPage";
import TimeSlotsPage from "./pages/timeSlotsPage/timeSlotsPage";
import TracksPage from "./pages/tracksPage/tracksPage";
import UsersPage from "./pages/usersPage/usersPage";
import DisabledPage from "./pages/disabledPage/disabledPage";
import EulaPage from "./pages/eulaPage/eulaPage";
import StealthPage from "./pages/stealthPage/stealthPage";
import AccountSettingsForm from "./forms/accountSettingsForm/accountSettingsForm";
import UserSettingsForm from "./forms/userSettingsForm/userSettingsForm";
import UserAdminForm from "./forms/userAdminForm/userAdminForm";
import SelfCard from "./cards/selfCard/selfCard";
import * as appService from "./services/app";
import * as authService from "./services/auth";
import * as accountService from "./services/account";
import * as userService from "./services/users";
import * as profileService from "./services/profile";
import * as notificationService from "./services/notifications";
import * as attributeService from "./services/attributes";
import * as timeSlotService from "./services/timeSlots";
import * as trackService from "./services/tracks";
import * as shiftService from "./services/shifts";
import * as timeOffService from "./services/timeOffs";
import * as tradeService from "./services/trades";
import * as volunteerService from "./services/volunteers";
import * as attendanceService from "./services/attendances";
import * as mobileService from "./services/mobile";
import {
    datetime,
    notifications as notificationUtils,
    objects,
    sorting,
    strings,
    users as userUtils
} from "@atttomyx/shared-utils";
import {branding as brandingUtils, firebase as firebaseUtils, mobile, router, timezones} from "@atttomyx/react-utils";
import {accounts as accountConstants, themes, time} from "@atttomyx/shared-constants";
import {getAdminItems, getUserItems, isTodoItem} from "./utils/intro";
import {
    ATTENDANCE_FILTER_DATA,
    getAttendanceFilters,
    getAttendanceReportFilters,
    getAttendanceReportFiltersData,
    getScheduleFilters,
    getTimeOffFilters,
    getTradeReportFilters,
    getTradeReportFiltersData,
    getUserFilters,
    getVolunteerReportFilters,
    getVolunteerReportFiltersData,
    SCHEDULE_FILTER_DATA,
    TIME_OFF_FILTER_DATA
} from "./filters/filters";
import {
    APP_ID_APPLE,
    APP_ID_GOOGLE,
    APP_NAME,
    APP_TAG_LINE,
    FIREBASE_CONFIG,
    NOTIFICATION_TYPES,
    PAGE_ABOUT,
    PAGE_ATTENDANCE,
    PAGE_ATTENDANCE_REPORT,
    PAGE_ATTRIBUTES,
    PAGE_HOME,
    PAGE_INTRO,
    PAGE_JOIN_ACCOUNT,
    PAGE_LOGIN,
    PAGE_MY_ACCOUNT,
    PAGE_NEW_USER,
    PAGE_NOTIFICATIONS,
    PAGE_OPTIONS,
    PAGE_PASSWORD,
    PAGE_PROFILE,
    PAGE_RECOVERY,
    PAGE_RECURRENCES,
    PAGE_SCHEDULE,
    PAGE_SETTINGS,
    PAGE_SHIFT,
    PAGE_STEALTH,
    PAGE_TIME_OFF,
    PAGE_TIME_OFF_REQUEST,
    PAGE_TIME_SLOTS,
    PAGE_TRADE_REPORT,
    PAGE_USER,
    PAGE_USERS,
    PAGE_VOLUNTEER_REPORT,
    PAGE_WELCOME,
    PATH_DEEP_LINK,
    PRIVATE_PAGES_EXACT,
    PRIVATE_PAGES_STARTS_WITH,
    PUBLIC_PAGES_EXACT,
    PUBLIC_PAGES_STARTS_WITH,
    TOPICS
} from "./constants";
import icon from "./icon.png";

const MOCK_PHONE = false;

const App = (props) => {
    const { snackbar, dimensions, history, onThemeChange } = props;
    const [ checklist, setChecklist ] = useState([]);
    const [ scheduleDate, setScheduleDate ] = useState(datetime.today());
    const [ scheduleView, setScheduleView ] = useState(null);
    const [ timeOffDate, setTimeOffDate ] = useState(datetime.today());
    const [ timeOffView, setTimeOffView ] = useState(null);
    const [ attendanceDate, setAttendanceDate ] = useState(datetime.today());

    const users = useEntities(userService.listUsers, snackbar.setError, "users", sorting.sortByLastNameAndFirstName);
    const deletedUsers = useEntities(userService.listDeletedUsers, snackbar.setError, "users", sorting.sortByLastNameAndFirstName);
    const notifications = useEntities(notificationService.listNotifications, snackbar.setError, "notifications", sorting.sortByCreatedDesc);
    const attributes = useEntities(attributeService.listAttributes, snackbar.setError, "attributes", sorting.sortByTitle);
    const timeSlots = useEntities(timeSlotService.listTimeSlots, snackbar.setError, "timeSlots", sorting.sortByTitle);
    const tracks = useEntities(trackService.listTracks, snackbar.setError, "tracks", sorting.sortByTitle);
    const shiftCache = useCachedByDateRange(shiftService.findShifts, snackbar.setError, "shifts", "start");
    const timeOffCache = useCachedByDateRange(timeOffService.findTimeOffs, snackbar.setError, "timeOffs", "start");

    const userRenderer = useRenderer(users, (user) => userUtils.getFullName(user));
    const attributeRenderer = useRenderer(attributes, (attribute) => attribute.title);

    const userFilters = useFilters(getUserFilters(attributeRenderer));
    const scheduleFilters = useFilters(getScheduleFilters(userRenderer, attributeRenderer), SCHEDULE_FILTER_DATA);
    const timeOffFilters = useFilters(getTimeOffFilters(userRenderer, attributeRenderer), TIME_OFF_FILTER_DATA);
    const attendanceFilters = useFilters(getAttendanceFilters(attributeRenderer), ATTENDANCE_FILTER_DATA);
    const attendanceReportFilters = useFilters(getAttendanceReportFilters(userRenderer, attributeRenderer), getAttendanceReportFiltersData());
    const volunteerReportFilters = useFilters(getVolunteerReportFilters(userRenderer, attributeRenderer), getVolunteerReportFiltersData());
    const tradeReportFilters = useFilters(getTradeReportFilters(userRenderer, attributeRenderer), getTradeReportFiltersData());

    const volunteersLoader = (cursor, limit, success, failure) => {
        if (bootstrap.user.roles.admin) {
            volunteerService.listVolunteers(cursor, limit, success, failure);

        } else {
            volunteerService.mineVolunteers(cursor, limit, success, failure);
        }
    };

    const volunteers = useEntities(volunteersLoader, snackbar.setError, "volunteers");

    const tradesLoader = (cursor, limit, success, failure) => {
        if (bootstrap.user.roles.admin) {
            tradeService.listTrades(cursor, limit, success, failure);

        } else {
            tradeService.mineTrades(cursor, limit, success, failure);
        }
    };

    const trades = useEntities(tradesLoader, snackbar.setError, "trades");

    const attendancesLoader = (cursor, limit, success, failure) => {
        if (bootstrap.user.roles.admin) {
            attendanceService.listAttendances(cursor, limit, success, failure);

        } else {
            attendanceService.mineAttendances(cursor, limit, success, failure);
        }
    };

    const attendances = useEntities(attendancesLoader, snackbar.setError, "attendances");

    const syncProfile = (profile) => {
        const timeZone1 = bootstrap.user.settings.timeZone;
        const timeZone2 = profile.settings.timeZone;

        if (strings.differ(timeZone1, timeZone2)
            && timezones.offsetsDiffer(timeZone1, timeZone2)) {

            if (bootstrap.user.roles.admin) {
                timeSlots.refresh();
                tracks.refresh();
            }

            shiftCache.refresh();
            timeOffCache.refresh();
        }

        bootstrap.syncProfile(profile);
    }

    const clearAll = () => {
        progress.clear();
        bootstrap.clear();
        users.clear();
        deletedUsers.clear();
        notifications.clear();
        attributes.clear();
        timeSlots.clear();
        tracks.clear();
        volunteers.clear();
        trades.clear();
        attendances.clear();
        shiftCache.clear();
        timeOffCache.clear();
        userFilters.clear();
        scheduleFilters.clear();
        timeOffFilters.clear();
        attendanceFilters.clear();
        attendanceReportFilters.clear();
    };

    const onLogout = () => {
        authService.cancelAllRequests("logged out");
        authService.clearAuthToken();
        timezones.clearTimeZone();

        clearAll();

        if (authService.restoreProxyTokenIfPossible()) {
            onLogin();

        } else {
            router.redirectToInitialPage(history, PUBLIC_PAGES_EXACT, PUBLIC_PAGES_STARTS_WITH, PAGE_LOGIN);
        }

        if (mobile.isMobile()) {
            mobile.lifecycle(true, false);
        }
    };

    const bootstrapSuccess = () => {
        const temporaryPassword = authService.getTemporaryPassword();

        if (strings.isNotBlank(temporaryPassword)) {
            router.redirectTo(history, PAGE_PASSWORD);

        } else {
            router.redirectToInitialPage(history, PRIVATE_PAGES_EXACT, PRIVATE_PAGES_STARTS_WITH, PAGE_HOME);
        }

        if (bootstrap.user.roles.admin) {
            deletedUsers.refresh();
            timeSlots.refresh();
            tracks.refresh();

        } else {
            deletedUsers.empty();
            timeSlots.empty();
            tracks.empty();
        }

        users.refresh();
        notifications.refresh();
        attributes.refresh();
        volunteers.refresh();
        trades.refresh();
        attendances.refresh();

        if (mobile.isMobile()) {
            mobile.lifecycle(true, true);
        }
    };

    const bootstrapFailure = (err) => {
        snackbar.setError(err);
        onLogout();
    };

    const bootstrap = useBootstrap(authService, accountService, profileService, notificationService,
        bootstrapSuccess, bootstrapFailure);

    const progress = useProgress(bootstrap,
        [users, deletedUsers, notifications, attributes, timeSlots, tracks, volunteers, trades, attendances],
        [],
        0,
        time.MILLISECONDS_IN_SECOND);

    const event = usePushEvent(bootstrap);

    const onLogin = (accounts) => {
        progress.refresh();
        bootstrap.refresh(accounts);
    };

    const onSwitchAccounts = (accounts) => {
        clearAll();
        onLogin(accounts);
        router.redirectTo(history, PAGE_HOME);
    };

    const onStealth = () => {
        onSwitchAccounts([]);
    };

    useEffect(() => {
        if (event && event.type) {
            const type = event.type;
            const data = event.payload || {};
            const payload = data.payload || {};
            const actor = data.actor || payload.actor || {};

            console.table(type, data);

            if (bootstrap.user && bootstrap.user.id !== actor.userId) {
                switch (type) {
                    // notifications

                    case "schedule_posted":
                        const showScheduleUntil = payload.showScheduleUntil;

                        if (showScheduleUntil && bootstrap.account) {
                            const modified = objects.deepCopy(bootstrap.account);

                            modified.settings.showScheduleUntil = showScheduleUntil;

                            bootstrap.syncAccount(modified);
                        }
                        break;
                    case "shift_added":
                    case "shift_available":
                    case "shift_removed":
                    case "shift_traded":
                        shiftCache.refresh();
                        break;
                    case "trade_approved":
                    case "trade_cancelled":
                    case "trade_removed":
                    case "trade_request":
                    case "trade_volunteer":
                        trades.refresh();
                        shiftCache.refresh();
                        break;
                    case "volunteer_added":
                    case "volunteer_removed":
                        volunteers.refresh();
                        break;

                    // events

                    case "attendance_saved":
                    case "attendance_deleted":
                        attendances.refresh();
                        break;
                    case "attribute_saved":
                    case "attribute_deleted":
                        attributes.refresh();
                        break;
                    case "shift_saved":
                    case "shift_deleted":
                        shiftCache.refresh();
                        break;
                    case "time_off_saved":
                    case "time_off_deleted":
                        timeOffCache.refresh();
                        break;
                    case "time_slot_saved":
                    case "time_slot_deleted":
                        timeSlots.refresh();
                        break;
                    case "track_saved":
                    case "track_deleted":
                        tracks.refresh();
                        break;
                    case "user_saved":
                    case "user_deleted":
                        users.refresh();
                        deletedUsers.refresh();
                        break;
                    default:
                        break;
                }

                if (notificationUtils.isEnabled(bootstrap.user, type)) {
                    const title = data.title;

                    if (strings.isNotBlank(title)) {
                        snackbar.setInfo(title);
                    }

                    if (notificationUtils.isPersist(bootstrap.user, type)) {
                        notifications.refresh();
                    }
                }
            }
        }
    }, [event]);

    useEffect(() => {
        const settings = bootstrap.user ? bootstrap.user.settings || {} : {};
        const theme = settings.theme || themes.LIGHT;
        const defaultBranding = brandingUtils.getBranding(theme);
        const branding = bootstrap.account ? bootstrap.account.branding : defaultBranding;
        const sanitized = {
            primary: branding.primary,
            secondary: branding.secondary,
            background: defaultBranding.background,
        };

        if (strings.isNotBlank(settings.timeZone)) {
            timezones.setTimeZone(settings.timeZone);

        } else {
            timezones.clearTimeZone();
        }

        if (mobile.isMobile()) {
            mobile.setBranding(theme, sanitized);
        }

        onThemeChange(theme, sanitized);
    }, [bootstrap.account, bootstrap.user]);

    useEffect(() => {
        if (mobile.isMobile()) {
            const showProgressBar = progress.loading || progress.paused;

            mobile.syncProgress(progress.percent, showProgressBar);
        }
    }, [progress.percent, progress.loading, progress.paused]);

    useEffect(() => {
        if (bootstrap.account && bootstrap.user && !users.loading) {
            if (bootstrap.user.roles.admin) {
                setChecklist(getAdminItems(bootstrap.account, bootstrap.user, users.entities));

            } else {
                setChecklist(getUserItems(bootstrap.account, bootstrap.user));
            }
        }
    }, [bootstrap.account, bootstrap.user, users.entities]);

    useEffect(() => {
        if (mobile.isMobile()) {
            mobileService.initMessagingTokenObserver(bootstrap);
        }
    }, [bootstrap]);

    useEffect(() => {
        appService.ensureOnLatestVersion(false, null);
        authService.setupAxiosInterceptors(onLogout);
        notificationUtils.initNotifications(NOTIFICATION_TYPES, TOPICS);
        firebaseUtils.initFirebase(FIREBASE_CONFIG);
        dimensions.onResize();

        if (mobile.isMobile()) {
            mobile.initializeMessageListener();
            mobileService.initDeepLinkObserver(history);
        }

        if (authService.loggedIn()) {
            onLogin();

        } else {
            onLogout();
        }

        return () => {
            if (mobile.isMobile()) {
                mobile.deactivateMessageListener();
            }
        }
    }, []);

    return <div className="app">
        {isMobile && !mobile.isMobile() && !mobile.isLocalhost() ?
            <AboutPage
                src={icon}
                appName={APP_NAME}
                appDescription={APP_TAG_LINE}
                appIdApple={APP_ID_APPLE}
                appIdGoogle={APP_ID_GOOGLE}
                appService={appService}
            /> : progress.loading || progress.paused ?
            <Splash
                percent={progress.percent}
            /> : bootstrap.account && bootstrap.user ?
            <>
                {MOCK_PHONE ? <PhoneStatusBar/> : null}
                <div className="content">
                    {bootstrap.account.status === accountConstants.STATUS_DISABLED ?
                        <Switch>
                            <Route path={PAGE_ABOUT}>
                                <AboutPage
                                    src={icon}
                                    appName={APP_NAME}
                                    appDescription={APP_TAG_LINE}
                                    appService={appService}
                                    account={bootstrap.account}
                                    user={bootstrap.user}
                                />
                            </Route>
                            <Route>
                                <DisabledPage/>
                            </Route>
                        </Switch> : !bootstrap.user.eula ?
                        <Switch>
                            <Route>
                                <EulaPage
                                    snackbar={snackbar}
                                    onAgree={(profile) => {
                                        bootstrap.syncProfile(profile);
                                        router.redirectTo(history, PAGE_HOME);
                                    }}
                                />
                            </Route>
                        </Switch> :
                        <Switch>
                            <Route exact path="/">
                                <Redirect to={PAGE_HOME}/>
                            </Route>
                            <Route path={PAGE_INTRO}>
                                {checklist.filter(isTodoItem).length > 0 ?
                                    <IntroPage
                                        user={bootstrap.user}
                                        checklist={checklist}
                                    /> :
                                    <Redirect to={PAGE_SCHEDULE}/>}
                            </Route>
                            <Route path={PAGE_SCHEDULE}>
                                <SchedulePage
                                    dimensions={dimensions}
                                    snackbar={snackbar}
                                    account={bootstrap.account}
                                    user={bootstrap.user}
                                    users={users.entities}
                                    deletedUsers={deletedUsers.entities}
                                    attributes={attributes.entities}
                                    tracks={tracks.entities}
                                    volunteers={volunteers.entities}
                                    trades={trades.entities}
                                    shiftCache={shiftCache}
                                    timeOffCache={timeOffCache}
                                    filters={scheduleFilters}
                                    date={scheduleDate}
                                    setDate={setScheduleDate}
                                    view={scheduleView}
                                    setView={setScheduleView}
                                    onSaveVolunteer={volunteers.onEntitySaved}
                                    onDeleteVolunteer={volunteers.onEntityDeleted}
                                    onSaveTrade={trades.onEntitySaved}
                                    onDeleteTrade={trades.onEntityDeleted}
                                    onSaveMyAccount={bootstrap.syncAccount}
                                />
                            </Route>
                            <Route path={PAGE_TIME_OFF}>
                                <TimeOffPage
                                    dimensions={dimensions}
                                    snackbar={snackbar}
                                    account={bootstrap.account}
                                    user={bootstrap.user}
                                    users={users.entities}
                                    attributes={attributes.entities}
                                    timeOffCache={timeOffCache}
                                    filters={timeOffFilters}
                                    date={timeOffDate}
                                    setDate={setTimeOffDate}
                                    view={timeOffView}
                                    setView={setTimeOffView}
                                />
                            </Route>
                            <Route exact path={PAGE_TIME_OFF_REQUEST}>
                                <AddTimeOffPage
                                    user={bootstrap.user}
                                    users={users.entities}
                                    timeOffCache={timeOffCache}
                                    date={timeOffDate}
                                />
                            </Route>
                            <Route path={`${PAGE_TIME_OFF_REQUEST}/:id`}
                                   render={props => <EditTimeOffPage
                                       {...props}
                                       snackbar={snackbar}
                                       user={bootstrap.user}
                                       users={users.entities}
                                       timeOffCache={timeOffCache}
                                       date={timeOffDate}
                                   />}
                            />
                            <Route path={PAGE_NOTIFICATIONS}>
                                <NotificationsPage
                                    dimensions={dimensions}
                                    snackbar={snackbar}
                                    notifications={notifications.entities}
                                    onBulkSave={notifications.onEntitiesSaved}
                                    onDelete={notifications.onEntityDeleted}
                                    notificationService={notificationService}
                                />
                            </Route>
                            <Route path={PAGE_PROFILE}>
                                <ProfilePage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSave={syncProfile}
                                    profileService={profileService}
                                    showImage={true}
                                    settingsForm={bootstrap.user.roles.admin ? UserAdminForm : SelfCard}
                                    settingsProps={bootstrap.user.roles.admin ? {
                                        attributes: attributes.entities,
                                        onSave: attributes.onEntitySaved,
                                    } : {
                                        attributes: attributes.entities,
                                    }}
                                />
                            </Route>
                            <Route path={PAGE_OPTIONS}>
                                <UserSettingsPage
                                    snackbar={snackbar}
                                    user={bootstrap.user}
                                    onSaveProfile={(profile) => {
                                        const tzChanged = bootstrap.user.settings.timeZone !== profile.settings.timeZone;

                                        // chicken
                                        bootstrap.syncProfile(profile);

                                        // egg
                                        if (tzChanged) {
                                            shiftCache.refresh();
                                            timeOffCache.refresh();
                                            attendances.refresh();

                                            if (bootstrap.user.roles.admin) {
                                                timeSlots.refresh();
                                                tracks.refresh();
                                            }
                                        }
                                    }}
                                    onSaveNotifications={bootstrap.syncNotifications}
                                    profileService={profileService}
                                    notificationService={notificationService}
                                    settingsForm={UserSettingsForm}
                                    allowApp={true}
                                    allowEmail={true}
                                />
                            </Route>
                            <Route path={PAGE_PASSWORD}>
                                <ChangePasswordPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onChanged={() => router.redirectTo(history, PAGE_HOME)}
                                />
                            </Route>
                            <Route path={PAGE_ABOUT}>
                                <AboutPage
                                    src={icon}
                                    appName={APP_NAME}
                                    appDescription={APP_TAG_LINE}
                                    appService={appService}
                                    account={bootstrap.account}
                                    user={bootstrap.user}
                                />
                            </Route>
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_MY_ACCOUNT}>
                                    <MyAccountPage
                                        snackbar={snackbar}
                                        account={bootstrap.account}
                                        onSave={bootstrap.syncAccount}
                                        myAccountService={accountService}
                                        showCode={false}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_SETTINGS}>
                                    <AccountSettingsPage
                                        snackbar={snackbar}
                                        account={bootstrap.account}
                                        onSave={bootstrap.syncAccount}
                                        myAccountService={accountService}
                                        settingsForm={AccountSettingsForm}
                                        showCode={true}
                                    />
                                </Route> : null}
                            <Route exact path={PAGE_USERS}>
                                <UsersPage
                                    dimensions={dimensions}
                                    snackbar={snackbar}
                                    account={bootstrap.account}
                                    user={bootstrap.user}
                                    users={users.entities}
                                    filters={userFilters}
                                    attributes={attributes.entities}
                                    onDelete={(user) => {
                                        users.onEntityDeleted(user.id);
                                        deletedUsers.onEntitySaved(user);
                                    }}
                                />
                            </Route>
                            {bootstrap.user.roles.admin ?
                                <Route exact path={PAGE_USER}>
                                    <AddUserPage
                                        snackbar={snackbar}
                                        onSave={(user) => {
                                            deletedUsers.onEntityDeleted(user.id);
                                            users.onEntitySaved(user);
                                            router.redirectTo(history, PAGE_USER + "/" + user.id);
                                        }}
                                        userService={userService}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route
                                    path={`${PAGE_USER}/:id`}
                                    render={(props) =>
                                        <EditUserPage
                                            {...props}
                                            snackbar={snackbar}
                                            user={bootstrap.user}
                                            users={users.entities}
                                            filters={userFilters}
                                            onSave={users.onEntitySaved}
                                            onPrev={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                            onNext={(userId) => router.redirectTo(history, PAGE_USER + "/" + userId)}
                                            userService={userService}
                                            showImage={true}
                                            settingsForm={UserAdminForm}
                                            settingsProps={{
                                                attributes: attributes.entities,
                                                onSave: attributes.onEntitySaved,
                                            }}
                                        />}
                                /> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_ATTRIBUTES}>
                                    <AttributesPage
                                        dimensions={dimensions}
                                        snackbar={snackbar}
                                        attributes={attributes.entities}
                                        onSave={attributes.onEntitySaved}
                                        onDelete={attributes.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_TIME_SLOTS}>
                                    <TimeSlotsPage
                                        dimensions={dimensions}
                                        snackbar={snackbar}
                                        timeSlots={timeSlots.entities}
                                        onSave={timeSlots.onEntitySaved}
                                        onDelete={timeSlots.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_RECURRENCES}>
                                    <TracksPage
                                        dimensions={dimensions}
                                        snackbar={snackbar}
                                        tracks={tracks.entities}
                                        onSave={tracks.onEntitySaved}
                                        onDelete={tracks.onEntityDeleted}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route exact path={PAGE_SHIFT}>
                                    <AddShiftPage
                                        snackbar={snackbar}
                                        settings={bootstrap.account.settings}
                                        user={bootstrap.user}
                                        users={users.entities}
                                        deletedUsers={deletedUsers.entities}
                                        attributes={attributes.entities}
                                        timeSlots={timeSlots.entities}
                                        tracks={tracks.entities}
                                        shiftCache={shiftCache}
                                        timeOffCache={timeOffCache}
                                        date={scheduleDate}
                                        onSaveAttribute={attributes.onEntitySaved}
                                        onSaveTimeSlot={timeSlots.onEntitySaved}
                                        onSaveTrack={tracks.onEntitySaved}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route exact path={`${PAGE_SHIFT}/:id`}
                                       render={props => <EditShiftPage
                                           {...props}
                                           snackbar={snackbar}
                                           user={bootstrap.user}
                                           attributes={attributes.entities}
                                           timeSlots={timeSlots.entities}
                                           tracks={tracks.entities}
                                           shiftCache={shiftCache}
                                           date={scheduleDate}
                                           onSaveAttribute={attributes.onEntitySaved}
                                           onSaveTimeSlot={timeSlots.onEntitySaved}
                                           onSaveTrack={tracks.onEntitySaved}
                                       />}
                                /> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={`${PAGE_SHIFT}/:id/:mode`}
                                       render={props => <EditShiftPage
                                           {...props}
                                           snackbar={snackbar}
                                           user={bootstrap.user}
                                           attributes={attributes.entities}
                                           timeSlots={timeSlots.entities}
                                           tracks={tracks.entities}
                                           shiftCache={shiftCache}
                                           date={scheduleDate}
                                           onSaveAttribute={attributes.onEntitySaved}
                                           onSaveTimeSlot={timeSlots.onEntitySaved}
                                           onSaveTrack={tracks.onEntitySaved}
                                       />}
                                /> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_ATTENDANCE}>
                                    <AttendancePage
                                        snackbar={snackbar}
                                        account={bootstrap.account}
                                        user={bootstrap.user}
                                        users={users.entities}
                                        deletedUsers={deletedUsers.entities}
                                        attributes={attributes.entities}
                                        timeSlots={timeSlots.entities}
                                        tracks={tracks.entities}
                                        volunteers={volunteers.entities}
                                        attendances={attendances.entities}
                                        shiftCache={shiftCache}
                                        timeOffCache={timeOffCache}
                                        filters={attendanceFilters}
                                        date={attendanceDate}
                                        setDate={setAttendanceDate}
                                        onSaveAttendance={attendances.onEntitySaved}
                                    />
                                </Route> : null}
                            <Route path={PAGE_ATTENDANCE_REPORT}>
                                {bootstrap.user.roles.admin ?
                                    <AttendanceReportPage
                                        users={users.entities}
                                        deletedUsers={deletedUsers.entities}
                                        attributes={attributes.entities}
                                        attendances={attendances.entities}
                                        filters={attendanceReportFilters}
                                    /> :
                                    <MyAttendancePage
                                        user={bootstrap.user}
                                        attendances={attendances.entities}
                                    />}
                            </Route>
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_VOLUNTEER_REPORT}>
                                    <VolunteerReportPage
                                        users={users.entities}
                                        deletedUsers={deletedUsers.entities}
                                        attributes={attributes.entities}
                                        volunteers={volunteers.entities}
                                        shiftCache={shiftCache}
                                        filters={volunteerReportFilters}
                                    />
                                </Route> : null}
                            {bootstrap.user.roles.admin ?
                                <Route path={PAGE_TRADE_REPORT}>
                                    <TradeReportPage
                                        users={users.entities}
                                        deletedUsers={deletedUsers.entities}
                                        attributes={attributes.entities}
                                        trades={trades.entities}
                                        shiftCache={shiftCache}
                                        filters={tradeReportFilters}
                                    />
                                </Route> : null}
                            <Route path={PAGE_JOIN_ACCOUNT}>
                                <JoinAccountPage
                                    snackbar={snackbar}
                                    accounts={bootstrap.accounts}
                                    onSwitchAccounts={(accounts) => onSwitchAccounts(accounts)}
                                    authService={authService}
                                    accountService={accountService}
                                />
                            </Route>
                            <Route path={`${PATH_DEEP_LINK}${PAGE_STEALTH}`}>
                                <StealthPage
                                    snackbar={snackbar}
                                    authService={authService}
                                    onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                    onLogin={onStealth}
                                />
                            </Route>
                            <Route path={`${PATH_DEEP_LINK}${PAGE_NEW_USER}`}>
                                <RedirectWithParams to={PAGE_JOIN_ACCOUNT}/>
                            </Route>
                            <Route path={`${PATH_DEEP_LINK}${PAGE_WELCOME}`}>
                                <RedirectWithParams to={PAGE_JOIN_ACCOUNT}/>
                            </Route>
                            <Route path={`${PATH_DEEP_LINK}${PAGE_LOGIN}`}>
                                <RedirectWithParams to={PAGE_HOME}/>
                            </Route>
                            <Route path={PAGE_LOGIN}>
                                <Redirect to={PAGE_HOME}/>
                            </Route>
                            <Route>
                                <NotFoundPage/>
                            </Route>
                        </Switch>}
                </div>
                <Footer
                    snackbar={snackbar}
                    accounts={bootstrap.accounts}
                    account={bootstrap.account}
                    user={bootstrap.user}
                    onLogout={onLogout}
                    onSwitchAccounts={() => onSwitchAccounts(bootstrap.accounts)}
                    numNotifications={notifications.entities.filter(notificationUtils.isNewNotification).length}
                    numTodos={checklist.filter(isTodoItem).length}
                    authService={authService}
                />
            </> :
            <>
                <PublicHeader
                    dimensions={dimensions}
                    appName={APP_NAME}
                    src={icon}
                />
                <div className="content">
                    <Switch>
                        <Route path={PAGE_LOGIN}>
                            <LoginPage
                                snackbar={snackbar}
                                authService={authService}
                                onForgotPassword={() => router.redirectTo(history, PAGE_RECOVERY)}
                                onNewUser={() => router.redirectTo(history, PAGE_NEW_USER)}
                                onLogin={onLogin}
                            />
                        </Route>
                        <Route path={PAGE_RECOVERY}>
                            <ForgotPasswordPage
                                snackbar={snackbar}
                                authService={authService}
                                onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                                appName={APP_NAME}
                            />
                        </Route>
                        <Route path={PAGE_NEW_USER}>
                            <NewUserPage
                                snackbar={snackbar}
                                authService={authService}
                                accountService={accountService}
                                onBackToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                                showCode={true}
                            />
                        </Route>
                        <Route path={`${PATH_DEEP_LINK}${PAGE_NEW_USER}`}>
                            <RedirectWithParams to={PAGE_NEW_USER}/>
                        </Route>
                        <Route path={`${PATH_DEEP_LINK}${PAGE_WELCOME}`}>
                            <WelcomePage
                                snackbar={snackbar}
                                authService={authService}
                                onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onLogin}
                            />
                        </Route>
                        <Route path={`${PATH_DEEP_LINK}${PAGE_STEALTH}`}>
                            <StealthPage
                                snackbar={snackbar}
                                authService={authService}
                                onToLogin={() => router.redirectTo(history, PAGE_LOGIN)}
                                onLogin={onStealth}
                            />
                        </Route>
                        <Route path={`${PATH_DEEP_LINK}${PAGE_LOGIN}`}>
                            <RedirectWithParams to={PAGE_LOGIN}/>
                        </Route>
                    </Switch>
                </div>
            </>}
        </div>
}

export default withRouter(App);
